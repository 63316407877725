@import url('https://fonts.googleapis.com/css2?family=EB+Garamond:ital,wght@0,400..800;1,400..800&family=Noto+Serif+TC:wght@200..900&display=swap');

.brand-primary {color:#FF7400;}

.dark-blue {color:#001927;}
.google-blue {color:#246df9;}
.black {color:#1d1d1b;}
.sand {color:#c1a077;}
.dirt {color:#ebdece;}
.dirt-bro {color:#f5ebe2;}

.profit-green {color:#48a600;}
.error {color: #bb0000;}

.text-gray {color: #a3a3a3;}

/* Background */
.sand-bg {background-color:#c1a077;}
.dirt-bg {background-color:#ebdece;}
.dirt-bro-bg {background-color:#f5ebe2;}


body {
    margin:0;
    padding:0;
    background-color: #000000;
}

h1, h2, h3, h4, h5, h6 {font-family: "Noto Serif TC", serif; letter-spacing: 1px;}

h2 {font-size: 48px;}
@media (max-width: 992px) {
    h2 {font-size: 36px;}
}

h3 {font-size: 32px;}
@media (max-width: 767px) {
    h3 {font-size: 24px;}
}

h4 {font-size: 24px;}
@media (max-width: 767px) {
    h4 {font-size: 22px;}
}

h5 {font-size: 20px;}
@media (max-width: 767px) {
    h5 {font-size: 18px;}
}


p {
    font-size: 16px;
    letter-spacing: 1px;
}
@media (max-width: 575px) {
    p {font-size: 14px;}
}



.fluid-padding {
    padding-left: 0px;
    padding-right: 0px;
}
@media (min-width: 768px) {
    .fluid-padding {
        padding-left: 25px;
        padding-right: 25px;
    }
}
@media (min-width: 1200px) {
    .fluid-padding {
        padding-left: 50px;
        padding-right: 50px;
    }
}
@media (min-width: 1400px) {
    .fluid-padding {
        padding-left: 100px;
        padding-right: 100px;
    }
}



button:focus {
    box-shadow: none !important;
}


.desktop-sm {
    display: block;
}
@media (max-width: 575px) {
    .desktop-sm {
        display: none;
    }
}
.mobile-sm {
    display: none;
}
@media (max-width: 575px) {
    .mobile-sm {
        display: block;
    }
}
.desktop-md {
    display: block;
}
@media (max-width: 767px) {
    .desktop-md {
        display: none;
    }
}
.mobile-md {
    display: none;
}
@media (max-width: 767px) {
    .mobile-md {
        display: block;
    }
}
.desktop-lg {
    display: block;
}
@media (max-width: 991px) {
    .desktop-lg {
        display: none;
    }
}
.mobile-lg {
    display: none;
}
@media (max-width: 991px) {
    .mobile-lg {
        display: block;
    }
}
