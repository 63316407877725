/* General Styling */
.mobile-view {
  width: 475px;
  overflow: hidden;
}

.main-btn {
  background-color: #FF7400;
  border-radius: 0;
  padding: 10px 20px;
}

.option-btn {
  border: 1px solid #FF7400;
  border-radius: 0;
  padding: 10px;
}

.option-btn-selected {
  border: 1px solid #FF7400;
  background-color: #FF7400;
  border-radius: 0;
  padding: 10px;
}

.heading-text {
  background-color: #FF7400;
  padding: 10px;
  color: #FFF;
}


.data-box {
  background-color: #000000aa;
  border: 1px solid #FF7400;
  border-radius: 10px;
  padding: 30px;
}

.data-box:hover {
  box-shadow: 0 2px 6px 0 #ff730070, 0 3px 6px 0 #ff730070;
  transition: 0.5s;
}

.divider {
  border-top: 1px solid #FF7400;
}





/* Header */
.header {
  background: #001927;
  padding: 20px 15px;
}

.header-logo {
  width: 100%;
  max-width: 250px;
}
@media (max-width: 768px) {
  .header-logo {
    max-width: 200px;
  }
}


/* Hero Banner */
.hero-banner {
  background-image: url('../img/hero-bg-desktop.jpg');
  background-position: center;
  background-size: cover;
}

.hero-heading {
  width: 100%;
  max-width: 700px;
  margin-top: 50px;
}

.hero-image {
  width: 100%;
  max-width: 992px;
}


.nft-image {
  width: 100%;
  max-width: 400px;
}
@media (max-width: 768px) {
  .nft-image {
    max-width: 280px;
  }
}


.about-us {
  background: #001927;
  padding: 50px 20px;
}




.usp {

}


.why-you-need {
  background: #FFF;
  padding: 80px 20px;
}






.about-us-2 {
  background: #001927;
  padding: 80px 20px;
}

.about-us-2-img {
  width: 100%;
  max-width: 420px;
}



.why-us {
  background: #1d1d1b;
  padding: 80px 20px;
}

.why-us-img {
  width: 100%;
  max-width: 420px;
}


.add-margin-top {
  margin-top: 100px;
}
@media (max-width: 768px) {
  .add-margin-top {
    margin-top: 50px;
  }
}




.gallery {
  background: #FFF;
  padding: 80px 20px;
}

.gallery-image {
  width: 100%;
}


.data-container {
  border: 1px solid #FF7400;
  padding: 10px;
}

.table-container {
  background-color: #000000aa;
  /* padding: 15px; */
}

.details-table {
  overflow-x: auto;
}

table {
  border: 1px solid #FF7400;
}

tbody {
  border-top: 1px solid #FF7400;
}

th {
  border: 1px solid #FF7400;
}

td {
  border-top: none;
}













input[type=number], input[type=number]:focus {
  background-color: #000000;
  border: 1px solid #FF7400;
  padding: 20px;
  border-radius: 0;
  color: #FFF;
}


input[type=text], input[type=text]:focus {
  width: 100%;
  padding: 8px 10px;
  font-size: 16px;
  background-color: #F3F5F7;
  border: none;
  border-radius: 5px;
  box-sizing: border-box;
  text-align: center;
}










